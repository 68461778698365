import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

import AddToSlackButton from 'components/buttons/add-to-slack'
import SecondaryParagraph from 'components/text/secondary-paragraph'

import instantToast from '../images/1-instant_toast.png'
import messengerToast from '../images/2-messenger_toast.png'
import dailyToast from '../images/3-daily_toast.png'
import commands from '../images/4-commands.png'

import appHomeImg from '../images/app-home.png'
import statsImg from '../images/stats.png'

const CallOut = styled.div`
	color: black;
	padding: 1rem;
	border: 1px solid silver;
	border-radius: 0.2rem;
	margin-top: -1rem;
`

const MainHeaderSectionContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const BigHeader = styled.div`
	font-size: calc(12vw + 20px);
	color: #8cc63f;
`

const SubHeader = styled.div`
	position: relative;
	top: calc(-0.6em);
	color: black;
	font-size: calc(2.6vw + 0.3vh);
`

const mBot1 = css`
	margin-bottom: 1rem;
`

const SLACK_APP_ID = process.env.SLACK_APP_ID

const IndexPage = () => (
	<>
		<Helmet
			meta={[
				{ name: 'slack-app-id', content: SLACK_APP_ID },
				{
					name: 'description',
					content: 'GitHub code review reminders right in Slack — without noise.',
				},

				// og
				{ name: 'og:site-name', content: 'Toast' },
				{
					name: 'og:title',
					content: 'Toast: GitHub code review reminders right in Slack',
				},
				{
					name: 'og:description',
					content:
						`Toast integrates Slack with GitHub, and only delivers notifications to the people involved. ` +
						`Keep the noise down in Slack and give context on what needs attention. ` +
						`See what’s on your plate, unblock teammates, and eliminate the overhead of tracking pull requests. ` +
						`Toast Slack commands showcase teams, identify bottlenecks, and prevent stale PRs. ` +
						`Push your code, open the PR, and add reviewers — Toast takes it from there.`,
				},
				{
					name: 'og:image',
					content: `https://toast.ninja/images/1600w/1-instant_toast.png`,
				},
				{ name: 'og:image:width', content: '1600' },
				{ name: 'og:image:height', content: '1000' },

				// twitter
				{ name: 'twitter:card', content: 'summary_large_image' },
				{ name: 'twitter:site', content: '@rea_loretta' },
			]}
		></Helmet>

		<Layout>
			<Container>
				<MainHeaderSectionContainer>
					{/* <CallOut>Recommended "Working from Home" app by Slack</CallOut> */}
					<div>
						<h1 css={mBot1}>
							<BigHeader>Merge</BigHeader>
							<SubHeader>Your Pull Request</SubHeader>
						</h1>
						{/* <div>
            <img
              css={css`
                margin-top: 2rem;
              `}
              src="images/192w/merge.png"
            />
          </div> */}
					</div>
					<GrayBodyText
						css={css`
							margin-bottom: 1rem;
							display: flex;
							flex-wrap: wrap;
							justify-content: center;
							div {
								margin-right: 10px;
							}
						`}
					>
						<div>Stay in the loop. </div>
						<div>Unblock teammates.</div>
						<div>Protect hack time.</div>
					</GrayBodyText>
					<AddToSlackButton size={60} text="Add to Slack" />
					<SecondaryParagraph>Toast cannot read your Slack messages.</SecondaryParagraph>

					{/* <RightPanelContainer /> */}
					<div
						css={css`
							display: flex;
							flex-direction: column;
							justify-content: center;
							width: 100%;
						`}
					>
						<ToastStatsAnnouncement>
							<div
								css={css`
									line-height: 1.1;
								`}
							>
								📊 Toast Stats now available.{' '}
							</div>
							<div
								css={css`
									font-size: 1rem;
									opacity: 0.5;
								`}
							>
								<div> Development Pipeline. Team dynamics. Areas of need.</div>
								<div> At a glance.</div>
							</div>
							<div
								css={css`
									opacity: 0.6;
									font-size: 0.8rem;
								`}
							>
								<b>/toast ask</b> to enable it.
							</div>
						</ToastStatsAnnouncement>
					</div>
				</MainHeaderSectionContainer>

				<div
					css={css`
						text-align: center;
						width: 100%;
					`}
				>
					<h4
						css={css`
							margin-bottom: 2rem;
							margin-top: 1rem;
							font-size: 1rem;
							color: #848484;
						`}
					>
						↓ Get the most out of Toast ↓
					</h4>
				</div>
				{/* <div
          css={css`
            border-radius: 20px;
            border: 20px solid #febe00;
          `}
        > */}
				<div
					css={css`
						display: flex;
						flex-direction: column;
						width: 100%;
						align-items: center;
					`}
				>
					<img
						css={css`
							border-radius: 20px 20px 0px 0px;
						`}
						src={instantToast}
						alt="main content"
					/>
					{/* </div> */}
					<img src={statsImg} alt="main content" />
					<img src={appHomeImg} alt="main content" />
					<img src={messengerToast} alt="main content" />
					<img src={dailyToast} alt="main content" />
					<img
						css={css`
							border-radius: 0px 0px 20px 20px;
						`}
						src={commands}
						alt="main content"
					/>
				</div>

				<div
					css={css`
						margin-top: 2rem;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 100%;
					`}
				>
					<AddToSlackButton size={60} />
					<SecondaryParagraph>Toast cannot read your Slack messages.</SecondaryParagraph>
				</div>

				{/* <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          img {
            width: 2rem;
            height: 2rem;
            margin-top: 2rem;
          }
        `}
      >
        <img src="images/192w/review.png" />
        <img src="images/192w/changes.png" />
        <img src="images/192w/merge.png" />
        <img src="images/192w/love.png" />
      </div> */}
			</Container>
		</Layout>
	</>
)

const ToastStatsAnnouncement = styled.div`
	border-radius: 20px;
	padding: 20px;
	background-image: linear-gradient(to top, #e1e8f9 0%, #edeff1 100%);
	text-align: center;
	width: 100%;
	color: #8cc63f;
	justify-content: center;
	${ '' /* font-size: calc(2.6vw + 0.3vh); */ }
	font-size: 1.5rem;

	color: black;
`

const GrayBodyText = styled.div`
	color: #333;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export default IndexPage
